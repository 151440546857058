import { gql } from '@apollo/client'

import { Meeting, MeetingMetadata } from 'types/meeting'

export const CREATE_MEETING = gql`
  mutation createMeeting($data: MeetingCreateInput!) {
    createOneMeeting(data: $data) {
      id
    }
  }
`

export interface CreateMeetingPayload {
  createOneMeeting: {
    id: string
  }
}

export interface CreateMeetingVariables {
  data: {
    calendlyUrl?: string
    duration?: number
    metadata: MeetingMetadata
    project?: {
      connect: {
        id: string
      }
    }
    prospect?: {
      connect: {
        id: string
      }
    }
    staffMember?: {
      connect: { id: string }
    }
    startTime?: Date | string | null
  }
}

export const DELETE_MEETING = gql`
  mutation deleteMeeting($where: MeetingWhereUniqueInput!) {
    deleteOneMeeting(where: $where) {
      id
    }
  }
`

export interface DeleteMeetingPayload {
  deleteOneMeeting: {
    id: string
  }
}

export interface DeleteMeetingVariables {
  where: {
    id: string
  }
}

export const FIND_MANY_MEETING = gql`
  query findManyMeeting(
    $take: Int
    $orderBy: [MeetingOrderByWithRelationInput!]
    $skip: Int
    $where: MeetingWhereInput
  ) {
    meetings: findManyMeeting(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      id
      canceledAt
      calendlyId
      calendlyResponse
      calendlyUrl
      duration
      metadata
      project {
        id
        data
        owners {
          isSubscribed
          isPrimary
          user {
            id
            auth0Id
            email
            firstName
            lastName
            picture
            type
          }
          userRole
        }
        staff {
          data
          isSubscribed
          isPrimary
          staffMember {
            id
            data
            email
            firstName
            lastName
            picture
          }
          staffMemberRole
        }
      }
      prospectId
      prospect {
        id
        email
        firstName
        lastName
      }
      staffMember {
        id
        auth0Id
        email
        firstName
        lastName
        phone
        picture
      }
      startTime
    }
  }
`

export interface FindManyMeetingPayload {
  meetings: Meeting[]
}

export interface FindManyMeetingVariables {
  skip?: number
  take?: number
  orderBy?: { startTime?: { sort: string } }
  where?: {
    prospectId?: {
      not: string
    }
    staffMemberId?: {
      equals: string
    }
    startTime?: {
      gte?: string
      lte?: string
    }
    AND?: {
      metadata: {
        path:
          | [keyof MeetingMetadata]
          | [
              keyof MeetingMetadata,
              Exclude<MeetingMetadata[keyof MeetingMetadata], undefined>,
            ]
        equals: string
      }
    }[]
  }
}

export const UPDATE_MEETING = gql`
  mutation updateMeeting(
    $data: MeetingUpdateInput!
    $where: MeetingWhereUniqueInput!
  ) {
    updateOneMeeting(data: $data, where: $where) {
      id
    }
  }
`

export interface UpdateMeetingPayload {
  updateOneMeeting: {
    id: string
  }
}

export interface UpdateMeetingVariables {
  data: {
    calendlyUrl?: string | null
    duration?: number | null
    metadata?: MeetingMetadata | null
    project?:
      | {
          connect: {
            id: string
          }
        }
      | { disconnect: boolean }
      | null
    staffMember?: {
      connect: { id: string }
    } | null
    startTime?: Date | null | string
  }
  where: {
    id: string
  }
}

export const FIND_UNIQUE_MEETING_SHALLOW = gql`
  query findUniqueMeeting($where: MeetingWhereUniqueInput!) {
    meeting: findUniqueMeeting(where: $where) {
      id
      canceledAt
      calendlyId
      calendlyResponse
      calendlyUrl
      duration
      metadata
      staffMember {
        email
        firstName
        lastName
        picture
      }
      startTime
    }
  }
`

export const FIND_UNIQUE_MEETING = gql`
  query findUniqueMeeting($where: MeetingWhereUniqueInput!) {
    meeting: findUniqueMeeting(where: $where) {
      id
      canceledAt
      calendlyId
      calendlyResponse
      calendlyUrl
      duration
      metadata
      project {
        id
        data
        owners {
          isSubscribed
          isPrimary
          user {
            id
            auth0Id
            email
            firstName
            lastName
            picture
            type
          }
          userRole
        }
        staff {
          data
          isSubscribed
          isPrimary
          staffMember {
            id
            data
            email
            firstName
            lastName
            picture
          }
          staffMemberRole
        }
      }
      prospectId
      prospect {
        id
        email
        firstName
        lastName
      }
      staffMember {
        id
        auth0Id
        email
        firstName
        lastName
        phone
        picture
      }
      startTime
    }
  }
`

export interface FindOneMeetingPayload {
  meeting: Meeting
}

export interface FindOneMeetingVariables {
  where: {
    id: string
  }
}

export const FIND_FIRST_MEETING = gql`
  query findFirstMeeting(
    $orderBy: [MeetingOrderByWithRelationInput!]
    $where: MeetingWhereInput
  ) {
    meeting: findFirstMeeting(orderBy: $orderBy, where: $where) {
      id
    }
  }
`

export interface FindFirstMeetingPayload {
  meeting: Meeting
}

export interface FindFirstMeetingVariables {
  orderBy?: { startTime?: string }
  where?: {
    duration?: {
      equals: number
    }
    prospectId?: {
      not: string
    }
    staffMemberId?: {
      equals: string
    }
    startTime?: {
      gte?: string
      lte?: string
      equals?: string | null
    }
    projectId?: {
      equals: string
    }
    AND?: {
      metadata: {
        path:
          | [keyof MeetingMetadata]
          | [
              keyof MeetingMetadata,
              Exclude<MeetingMetadata[keyof MeetingMetadata], undefined>,
            ]
        equals: string
      }
    }[]
  }
}

export const CREATE_SC_WALK_IN_MEETING = gql`
  mutation createSelectionCenterWalkInMeeting(
    $data: SelectionCenterWalkInMeetingInput!
  ) {
    createSelectionCenterWalkInMeeting(data: $data) {
      id
    }
  }
`

export interface CreateSelectionCenterWalkInMeetingPayload {
  createSelectionCenterWalkInMeeting: {
    id: string
  }
}

export interface CreateSelectionCenterWalkInMeetingVariables {
  data: {
    selectionCenterId: string
    firstName: string
    lastName: string
    email: string
  }
}
